:root
  --primary-color: #173029
  --color-primary: #173029
  --primary-r: 23
  --primary-g: 48
  --primary-b: 41
  --primary-h: 163
  --primary-s: 35.2%
  --primary-l: 13.9%
  --switch: 100%
:root
  --secondary-color: #FFFFFF
  --color-secondary: #FFFFFF
